// @flow

import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import * as React from 'react';
import injectSheet from 'react-jss';
// eslint-disable-next-line flowtype-errors/show-errors
import { connect } from 'react-redux';

import * as Backend from '../backend/legacyJsonBackend';
import type { FormInput, InputValue } from '../inputs/types';
import * as InputTypes from '../inputs/types';
import type { Dispatch } from '../store';
import MultipleQuestionsElement from './MultipleQuestionsElement';
import NoQuestionsElement from './NoQuestionsElement';
import { LabeledSingleQuestionElement } from './SingleQuestionElement';

const style = {
  element: {
    pageBreakInside: 'avoid',
    marginBottom: 40,
    '& ol': {
      margin: 0,
      padding: 0,
    },
    '& ul li': {
      background: 'none',
      listStyleType: 'square',
      marginLeft: '25px',
      paddingLeft: 10,
    },
    '& ol li': {
      marginLeft: '25px',
      paddingLeft: 10,
    },
  },
  subHeading: { marginBottom: 15 },
};
type Classes = { [$Keys<typeof style>]: string };

const disabledInput: InputTypes.FormInput => InputTypes.FormInput = input => ({
  ...input,
  value: { ...input.value, disabled: true },
});

const Element = injectSheet(style)(
  (p: {
    classes: Classes,
    elem: Backend.Element,
    gotInputValue: (
      elementId: number,
      questionId: number,
      value: ?InputValue,
    ) => void,
    inputs: FormInput[],
    postHeading?: React.Node,
    disabled?: boolean,
  }) => {
    return (
      <div
        className={classNames([
          p.classes.element,
          p.elem.elementType === 'HEADING' && p.classes.subHeading,
        ])}
      >
        <ElementComponent
          element={p.elem}
          postHeading={p.postHeading}
          inputs={p.disabled ? p.inputs.map(disabledInput) : p.inputs}
          gotInputValue={p.gotInputValue}
        />
      </div>
    );
  },
);

const ElementComponent = ({ element, inputs, gotInputValue, postHeading }) => {
  const {
    questions,
    elementType: type,
    elementId: id,
    title,
    description,
  } = element;

  if (type === 'SUBMISSION_REFERENCE') {
    return null;
  }

  if (
    [
      'ATTACHMENT',
      'DATE',
      'QUESTION',
      'QUESTION_MULTILINE',
      'NUMBER',
      'PHONE',
      'RADIO',
      'CHECKBOX',
      'SELECT',
      'NATIONAL_ID_NUMBER',
      'IDPORTEN_NATIONAL_ID',
      'SUBMISSION_REFERENCE',
      'EMAIL',
      'USERNAME',
      'NAME',
      'LINEAR_SCALE',
    ].includes(type)
  ) {
    const question = questions[0];
    const input = inputs[0];
    if (input == null) {
      throw 'input not found for field';
    }
    return (
      <LabeledSingleQuestionElement
        type={type}
        input={input}
        postHeading={postHeading}
        onInputValue={value => {
          gotInputValue(id, question.questionId, value);
        }}
        question={question}
        repeat={element.repeatedNationalIdRequired}
        dateFormat={element.dateFormat}
        linearScaleType={element.linearScaleType && element.linearScaleType}
        maxNumberOfCharacters={element.maxNumberOfCharacters}
        isAnswerHashed={element.isAnswerHashed}
      />
    );
  } else if (['MATRIX_RADIO', 'MATRIX_CHECKBOX'].includes(type)) {
    return (
      <MultipleQuestionsElement
        id={id}
        title={title}
        description={description}
        questions={questions}
        type={type}
        postHeading={postHeading}
        answerOptions={element.answerOptions}
        inputs={inputs}
        onInputValue={(questionId, value) => {
          gotInputValue(id, questionId, value);
        }}
      />
    );
  } else if (['HEADING', 'TEXT', 'IMAGE'].includes(type)) {
    return <NoQuestionsElement preContent={postHeading} element={element} />;
  }
};

export const ConnectedElement = connect(
  (
    { answer: { inputs } },
    ownProps: {
      element: Backend.Element,
      postHeading?: React.Node,
      disabled?: boolean,
    },
  ) => {
    const { elementType: type, questions } = ownProps.element;
    let inputsForElement = [];
    const question = questions[0];
    if (['MATRIX_RADIO', 'MATRIX_CHECKBOX'].includes(type)) {
      inputsForElement = inputs.filter(
        input =>
          questions.find(
            question => input.questionId === question.questionId,
          ) != null,
      );
    } else if (!['HEADING', 'TEXT', 'IMAGE'].includes(type)) {
      inputsForElement = inputs.filter(
        input => input.questionId === question.questionId,
      );
    } else if (['LINEAR_SCALE'].includes(type)) {
      inputsForElement = inputs.filter(
        input => input.questionId === question.questionId,
      );
    }
    return {
      inputs: inputsForElement,
      elem: ownProps.element,
      postHeading: ownProps.postHeading,
      disabled: ownProps.disabled,
    };
  },
  (dispatch: Dispatch) => ({
    gotInputValue: (
      elementId: number,
      questionId: number,
      value: InputValue,
    ) => {
      dispatch({ type: 'GOT_INPUT_VALUE', elementId, questionId, value });
    },
  }),
  null,
  {
    pure: true,
    areStatePropsEqual: (prev, next) => {
      return isEqual(prev.inputs, next.inputs);
    },
  },
)(Element);
