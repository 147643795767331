// @flow

import { Trans, t } from '@lingui/macro';
import classNames from 'classnames';
import * as React from 'react';
// $FlowIgnore
import { useState } from 'react';
import injectSheet from 'react-jss';
// $FlowIgnore
import { useSelector } from 'react-redux';
import { responsive } from 'react-usit-ui';

import getSettings from '../backend/settings';
import { infoMessage, link, ns } from '../design/colors/index';
import { underlineButtonLink } from '../design/focusFrame';
import i18n from '../i18n';
import collapse from './collapse-blue.svg';
import expand from './expand-blue.svg';

const style = {
  infoMessageWrapper: {
    backgroundColor: infoMessage.background,
    color: infoMessage.fontColor,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 10,
    lineHeight: '28px',
    fontSize: 18,
  },
  infomessageContainer: {
    margin: 'auto',
    maxWidth: 970,
  },
  personalInfoMessageShort: {
    fontSize: 18,
  },
  personalInfoMessageShortText: {
    marginRight: 5,
  },
  expandCollapse: {
    background: `url(${expand}) no-repeat`,
    backgroundPosition: '100% 50%',
    paddingRight: 15,
    cursor: 'pointer',
    color: link.default,
    border: 'none',
    fontSize: 18,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    lineHeight: '28px',
    ...underlineButtonLink,
  },
  collapse: {
    background: `url(${collapse}) no-repeat`,
    backgroundPosition: '100% 50%',
  },
  personalInfoMessageExpanded: {
    fontSize: 16,
    color: ns.black,
  },
  personalInfoMessageList: {
    listStyleType: 'square',
    marginLeft: 18,
    marginTop: 15,

    '& li': {
      marginBottom: 10,
    },
  },
  link: {
    color: link.default,
    fontWeight: 'bold',
  },
  [responsive.media.max640]: {
    infoMessageWrapper: {
      maxWidth: '100%',
      fontSize: 16,
    },
    personalInfoMessageShortText: {
      fontSize: 16,
      hyphens: 'auto',
      '-webkit-hyphens': 'auto',
      '-ms-hyphens': 'auto',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
    },
    expandCollapse: {
      fontSize: 16,
    },
    personalInfoMessageExpanded: {
      fontSize: 15,
    },
  },
};

type Classes = { [$Keys<typeof style>]: string };

const _InfoMessageContainer = (p: { classes: Classes }) => {
  const tsdLink = i18n._(
    t`https://www.uio.no/tjenester/it/forskning/sensitiv/`,
  );
  const [infoMessageExpanded, setinfoMessageExpanded] = useState(false);
  const form = useSelector(state => state.answer.form);

  const EditorsContactAddressAsLink = ({ editorsContactAddress }) => (
    <span
      className={p.classes.link}
      dangerouslySetInnerHTML={{ __html: editorsContactAddress }}
    />
  );

  const editorsContactAddress = form && form.meta && (
    <EditorsContactAddressAsLink
      editorsContactAddress={form.meta.editorsContactAddressAsLink}
    />
  );

  const isIdPortenRespondentGroup = (respondentGroup): boolean => {
    if (
      respondentGroup === 'ID_PORTEN_LEVEL_3' ||
      respondentGroup === 'ID_PORTEN_LEVEL_3_SIGNING' ||
      respondentGroup === 'ID_PORTEN_LEVEL_4' ||
      respondentGroup === 'ID_PORTEN_LEVEL_4_SIGNING'
    ) {
      return true;
    }
    return false;
  };

  function containsAutofilledNorwegianNationalId(pages) {
    let found = false;
    pages.forEach(page => {
      page.elements.forEach(element => {
        if (
          element.nationalIdNumberType === 'NORWEGIAN_ID_NUMBER' &&
          element.autofill
        ) {
          found = true;
        }
      });
    });
    return found;
  }

  return (
    <div className={p.classes.infoMessageWrapper}>
      {form && form.meta && (
        <div className={p.classes.infomessageContainer}>
          <div className={p.classes.personalInfoMessageShort}>
            <span className={p.classes.personalInfoMessageShortText}>
              {form.formId == 122365 || form.formId == 149669 ? (
                <Trans>
                  Du kan være anonym når du svarer på dette skjemaet
                </Trans>
              ) : form.meta.anonymous ? (
                <Trans>Skjemaet skal være anonymt.</Trans>
              ) : form.meta.shouldGetRespondentInfoFromPerson ||
                form.meta.containsDirectlyPersonIdentifyingQuestions ? (
                <Trans>Skjemaet lagrer personinformasjon.</Trans>
              ) : (
                <Trans>
                  Skjemaet har spørsmål som kan være person&shy;identifiserende.
                </Trans>
              )}
            </span>
            {!form.meta.deliveryDestinationIsTsd && (
              <button
                className={classNames([
                  p.classes.expandCollapse,
                  infoMessageExpanded && p.classes.collapse,
                ])}
                onClick={() => setinfoMessageExpanded(!infoMessageExpanded)}
              >
                {infoMessageExpanded ? (
                  <Trans>Lukk</Trans>
                ) : (
                  <Trans>Vis mer</Trans>
                )}
              </button>
            )}

            {form.meta.deliveryDestinationIsTsd && (
              <div className={p.classes.personalInfoMessageShortText}>
                <Trans>
                  Svarene lagres i Tjenester for sensitive data (TSD).
                </Trans>
                <button
                  className={classNames([
                    p.classes.expandCollapse,
                    infoMessageExpanded && p.classes.collapse,
                  ])}
                  onClick={() => setinfoMessageExpanded(!infoMessageExpanded)}
                >
                  {infoMessageExpanded ? (
                    <Trans>Lukk</Trans>
                  ) : (
                    <Trans>Les mer</Trans>
                  )}
                </button>
              </div>
            )}
          </div>
          {infoMessageExpanded && (
            <div className={p.classes.personalInfoMessageExpanded}>
              {form.formId == 122365 || form.formId == 149669 ? (
                <ul className={p.classes.personalInfoMessageList}>
                  <li>
                    <Trans>
                      Vi lagrer ingen kobling mellom deg og svaret ditt, selv om
                      du er logget inn. Skjemaeier ser kun svar du velger å
                      fylle inn.
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      Det skal være frivillig å svare på spørsmål om
                      personopplysninger
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      Finner du et obligatorisk spørsmål om
                      personopplysninger? &nbsp;
                      <a
                        href="mailto:nettskjema@usit.uio.no"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={p.classes.link}
                      >
                        Meld fra til brukerstøtte
                      </a>
                    </Trans>
                  </li>
                </ul>
              ) : form.meta.anonymous ? (
                <ul className={p.classes.personalInfoMessageList}>
                  <li>
                    <Trans>
                      Det lagres ingen informasjon automatisk om brukerkontoen
                      din, selv om du skulle være logget inn
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      Skjemaet skal ikke innholde spørsmål som gjør at svaret
                      ditt kan kobles til deg som person. Dersom du mener
                      skjemaet likevel stiller spørsmål som gjør det mulig å
                      identifisere enkeltpersoner, meld fra til ansvarlig for
                      skjemaet, {editorsContactAddress}.
                    </Trans>
                  </li>
                </ul>
              ) : form.meta.shouldGetRespondentInfoFromPerson ? (
                <ul className={p.classes.personalInfoMessageList}>
                  <li>
                    <Trans>Navn</Trans>
                  </li>
                  <li>
                    <Trans>E-postadresse</Trans>
                  </li>
                  <li>
                    <Trans>Svarene dine</Trans>
                  </li>
                </ul>
              ) : (
                <ul className={p.classes.personalInfoMessageList}>
                  <li>
                    <Trans>
                      Skjemaet har spørsmål som kan gjøre det mulig å koble deg
                      som person til svaret ditt
                    </Trans>
                  </li>
                  <li>
                    <Trans>
                      Vi samler kun inn den informasjonen du selv oppgir, eller
                      som er ferdigutfylt i skjemaet
                    </Trans>
                  </li>
                  {form &&
                    form.meta.deliveryDestinationIsTsd &&
                    isIdPortenRespondentGroup(form.meta.respondentGroup) &&
                    containsAutofilledNorwegianNationalId(form.pages) && (
                      <li>
                        <Trans>
                          ID-porten-innlogging fører til lagring av en unik
                          bruker-ID i TSD for Nettskjema/TSD.
                        </Trans>
                      </li>
                    )}
                </ul>
              )}

              {form.meta.deliveryDestinationIsTsd && (
                <div>
                  <Trans>
                    Informasjonen lagres i{' '}
                    <a href={tsdLink} target="_blank" rel="noopener noreferrer">
                      Tjenester for sensitive data,{' '}
                    </a>
                    som oppfyller lovkravene for sikker behandling av
                    personopplysninger.
                  </Trans>
                </div>
              )}

              {getSettings().supportsIncludingRefererUrl && (
                <div>
                  <Trans>
                    Informasjon om hvilken nettside du kom fra blir lagret.
                  </Trans>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const shouldHideAnonymousMessage = () => {
  const anonymousMessageParameter = new URLSearchParams(
    document.location.search,
  ).get('hideAnonymousMessage');

  return (
    getSettings().anonymous &&
    (anonymousMessageParameter === '1' || anonymousMessageParameter === 'true')
  );
};

export const ConnectedInfoMessageContainer = injectSheet(style)(
  _InfoMessageContainer,
);
