// @flow

import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import classNames from 'classnames';
import * as React from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';

import { link, primary, white } from '../design/colors/index';
import * as ModeTypes from '../modes/types';
import nettskjemaLogo from './nettskjema-logo.svg';

export type ThemeType = {
  palette: any,
  typography: any,
  graphics: any,
  props: any,
};

export type PropsType = {
  publicPath: string,
};

const style = (theme: Object) => ({
  preFooterContainer: {
    backgroundColor: primary.light,
    color: primary.footer,
    paddingLeft: 60,
    paddingRight: 40,
  },

  preFooterContent: {
    display: 'flex',
    maxWidth: 1340,
    height: 140,
    fontWeight: 'bold',
    fontSize: 23,
    margin: 'auto',
  },

  themeLogo: {
    flex: '0 0 30%',
    background: `url(${theme.props.publicPath +
      theme.graphics.footer.logoBackground.url})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: theme.graphics.footer.logoBackground.size,
    backgroundPositionY: 'center',
    marginRight: 60,
  },

  formContactContainer: {
    alignSelf: 'center',

    '& span a ': {
      fontWeight: 'normal',
      textDecoration: 'underline',
      color: link.default,
    },
    '& span a:hover': {
      backgroundColor: primary.footerHover,
      color: white,
    },

    '& span a:focus': {
      border: '2px solid ' + primary.footerFocus,
    },
  },

  footerContainer: {
    backgroundColor: primary.footer,
    color: primary.footer,
    paddingLeft: 60,
    paddingRight: 40,
  },

  footerContent: {
    display: 'flex',
    maxWidth: 1340,
    height: 180,
    color: white,
    fontSize: 16,
    margin: 'auto',
    paddingTop: 30,
    paddingBottom: 30,
  },

  nettskjemaLogo: {
    display: 'flex',
    marginRight: 60,
    flex: '0 0 30%',
    alignItems: 'center',
    columnGap: 8,
    '& img': {
      width: 63,
    },
    '& span': {
      fontWeight: 700,
      fontSize: '2.7rem',
      letterSpacing: '0.215rem',
    },
  },

  termsItem: {},

  responsibleItem: {
    paddingLeft: 100,
  },

  header: {
    marginBottom: 10,
    fontSize: 12,
  },

  footerLink: {
    '&:hover ': {
      backgroundColor: primary.footerHover,
    },

    '&:focus': {
      border: '2px solid ' + primary.footerFocus,
    },

    color: white,
    textDecoration: 'underline',
    marginBottom: 5,
  },

  feedbackButton: {
    backgroundColor: 'transparent',
    fontSize: 'inherit',
    fontWeight: 'bold',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  },
  feedbackIcon: {
    marginRight: 17,
  },

  '@media all and (max-width: 970px)': {
    preFooterContainer: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    preFooterContent: {
      flexDirection: 'column-reverse',
      height: 'auto',
    },
    footerContainer: {
      height: 'auto',
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
    },

    footerContent: {
      flexDirection: 'column',
      height: 'auto',
    },

    themeLogo: {
      flex: 'none',
      height: 140,
      backgroundPositionX: 'center',
      backgroundSize: theme.graphics.footer.logoBackground.mobSize
        ? theme.graphics.footer.logoBackground.mobSize
        : theme.graphics.footer.logoBackground.size,
      marginLeft: 0,
      marginRight: 0,
    },

    formContactContainer: {
      textAlign: 'center',
      overflowWrap: 'anywhere',
      marginTop: 40,
      '& a': {
        display: 'block',
      },
    },

    nettskjemaLogo: {
      flex: 'none',
      width: '100%',
      height: 85,
      paddingLeft: 20,
      marginLeft: 0,
    },

    termsItem: {
      marginLeft: 0,
      paddingLeft: 20,
      marginTop: 30,
    },
    responsibleItem: {
      marginTop: 30,
      paddingLeft: 20,
    },
  },
});

type Classes = { [$Keys<$Call<typeof style, Object>>]: string };

const FormContactLink = ({ editorsContact }) => (
  <span dangerouslySetInnerHTML={{ __html: editorsContact }} />
);

const FooterLink = injectSheet(style)(({ url, children, classes }) => (
  <a
    href={url}
    className={classes.footerLink}
    rel="noopener noreferrer"
    target="_blank"
  >
    <span>{children}</span>
  </a>
));

const Footer_ = (p: {
  editorsContact: string,
  mode?: ModeTypes.StandaloneMode,
  classes: Classes,
}) => {
  const formUrl = window.location.href;

  return (
    <I18n>
      {({ i18n }) => (
        <>
          <div className={p.classes.preFooterContainer}>
            <div className={p.classes.preFooterContent}>
              <div className={classNames(p.classes.themeLogo)}></div>

              <div className={classNames(p.classes.formContactContainer)}>
                <FormContactLink
                  editorsContact={p.editorsContact}
                  body={i18n._(t`Sendt fra ${formUrl}`)}
                  classes={p.classes}
                />
              </div>
            </div>
          </div>

          <div className={p.classes.footerContainer}>
            <div className={p.classes.footerContent}>
              <div className={p.classes.nettskjemaLogo}>
                <img alt={''} src={nettskjemaLogo} />
                <span>NETTSKJEMA</span>
              </div>

              <div className={classNames(p.classes.termsItem)}>
                <div className={p.classes.header}>
                  <Trans>VILKÅR</Trans>
                </div>
                <div>
                  <FooterLink
                    url={i18n._(
                      t`https://www.uio.no/tjenester/it/adm-app/nettskjema/mer-om/personvern/tos.html`,
                    )}
                  >
                    <Trans>Personvern og vilkår for bruk</Trans>
                  </FooterLink>
                </div>
                <div>
                  <FooterLink
                    url={i18n._(
                      t`https://www.uio.no/tjenester/it/adm-app/nettskjema/mer-om/personvern/cookies.html`,
                    )}
                  >
                    <Trans>Informasjonskapsler</Trans>
                  </FooterLink>
                </div>
                <div>
                  <FooterLink
                    url={i18n._(
                      t`https://uustatus.no/nb/erklaringer/publisert/f1a45f49-3ae3-4418-8903-38401968458e`,
                    )}
                  >
                    <Trans>Tilgjengelighetserklæring</Trans>
                  </FooterLink>
                </div>
              </div>

              <div className={classNames(p.classes.responsibleItem)}>
                <div className={p.classes.header}>
                  <Trans>NETTSKJEMA ER UTVIKLET OG DESIGNET AV</Trans>
                </div>
                <div>
                  <FooterLink url={i18n._(t`https://uio.no`)}>
                    <Trans>Universitetet i Oslo</Trans>
                  </FooterLink>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </I18n>
  );
};

export const Footer = injectSheet(style)(Footer_);

export const ConnectedFooter = connect(({ answer }) => ({
  editorsContact: answer.form && answer.form.meta.editorsContact,
  isReceiptPage: answer.submissionResponse != null,
}))(Footer);

export default ConnectedFooter;
