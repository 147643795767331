import { ApiUserOwner, Tab } from './pages/user/form/types';

// Nettskjema window type taken from head.ftl
type nsWindowObject = {
  FORM_IDS_FOR_DELIVERY_TO_NETTSKJEMA_API: string;
  TSD_PROJECTS_FOR_DELIVERY_TO_NETTSKJEMA_API: string;
  CONTEXT_PATH: string;
  BASE_URL: string;
  CSRF_PREVENTION_TOKEN: string;

  environment?: 'DEFAULT' | 'DEMO' | 'UTV';

  isEnglish: boolean;
  isResponsive: boolean;

  loginPath?: string;

  isLoggedIn: boolean;
  isSupportuser: boolean;
  isSuperuser: boolean;
  isLoggedInWithIdPorten: boolean;
  isAdministrativeUser: boolean;
  isPersonalDataResponsible: boolean;

  operationMessage?: string;
  showTsdBanner: boolean;

  authenticatedInfo: AuthenticatedInfo;

  canEditForm?: boolean;

  formId?: number;
  invitationId?: number;
  languageCode?: string;
  formLanguage?: string;
  startTime: number;
  supportsIncludingRefererUrl: boolean;
  CopyFormWithoutDialog?: FormDialogToggle;
  OpenCopyFormDialog?: FormDialogToggle;
  OpenMigrateFormDialog?: FormDialogToggle;

  settingsLocked?: boolean;

  editorsThroughApiUsers?: ApiUserOwner[];

  breadcrumbs: { text: string; url: string }[];
  currentTab: Tab;

  shouldShowPersonalInfoWarningPopUp?: boolean;
  isCodebookActivated?: boolean;
  tsdProject?: string;

  errorMessage?: string;
};

type FormDialogToggle = { toggle: (formId: number) => void };

type personWindowObject = {
  hasAcceptedTos: boolean;
};

type reactFormWindowObject = {
  metaInformation: FormMetaInformationFromFTL;
  formSettings: FormSettingsFromFTL;
};

export type FormMetaInformationFromFTL = {
  formId: number;
  shouldGetRespondentInfoFromPerson: boolean;
  modifiedDate?: string;
  modifiedBy?: any;
  readyForHighSecurityServiceActivation: boolean;
  codebookValuesComplete: boolean;
  hasValidExternalIdsForTsd: boolean;
  sendingSubmissionInEmailToEditor: boolean;
  hasSubmissions: boolean;
  settingsLocked: boolean;
  canEditForm: boolean;
  tsdProject?: string;
};

export type FormSettingsFromFTL = {
  title: string;
  titleShort?: string;
  formType: string;
  openFrom: string;
  openTo: string;
  codebookActivated: boolean;
  editors: {};
  netgroupsEditor: {};
  personsWithCopyPermission: {};
  netgroupsWithCopyPermission: {};
  respondentGroup: RespondentGroup;
  theme: Theme;
  shouldPreventDataManipulation: boolean;
  elements: {};
  open: boolean;
  possibleToHaveInvitations: boolean;
};

declare global {
  interface Window {
    Nettskjema: nsWindowObject;
    messages: Record<string, string>;
    Person: personWindowObject;
    ReactForm: reactFormWindowObject;
  }
}

export type AuthenticatedInfo = {
  username: string;
  isSuperuser: boolean;
  isSupportuser: boolean;
  displayName: string;
  logoutLink: string;
};

export type FormDialogPage =
  | 'DEFAULT'
  | 'SIGN_UP'
  | 'MULTIPLE_CHOICE'
  | 'TEMPLATE'
  | 'PERSONAL_INFO'
  | 'PERSONAL_DATA';

export type FormType =
  | 'DEFAULT'
  | 'SIGN_UP'
  | 'MULTIPLE_CHOICE'
  | 'HIGH_SECURITY'
  | 'TEMPLATE';

export type DeliveryDestination =
  | 'DATABASE'
  | 'DATABASE_ENCRYPTED'
  | 'TSD'
  | 'EMAIL_ONLY';

export type PersonalInfoElement =
  | 'NAME'
  | 'EMAIL'
  | 'USERNAME'
  | 'NATIONAL_ID_NUMBER'
  | 'NATIONAL_ID_NUMBER_AUTOFILLED';

export type PersonalDataPurpose =
  | 'STUDY_OR_EDUCATION'
  | 'EMPLOYEE'
  | 'RESEARCH'
  | 'OTHER';

export type FormLanguage = 'nb' | 'nn' | 'en';
export type RespondentGroup =
  | 'ALL'
  | 'FEIDE'
  | 'INVITED'
  | 'ID_PORTEN_LEVEL_3'
  | 'ID_PORTEN_LEVEL_4'
  | 'ID_PORTEN_LEVEL_3_SIGNING'
  | 'ID_PORTEN_LEVEL_4_SIGNING'
  | 'HELSENORGE';

export const isIdPortenRespondentGroup = (
  respondentGroup?: RespondentGroup,
): boolean => {
  if (
    respondentGroup === 'ID_PORTEN_LEVEL_3' ||
    respondentGroup === 'ID_PORTEN_LEVEL_3_SIGNING' ||
    respondentGroup === 'ID_PORTEN_LEVEL_4' ||
    respondentGroup === 'ID_PORTEN_LEVEL_4_SIGNING'
  ) {
    return true;
  }
  return false;
};

export type ShowAnswer =
  | 'NONE'
  | 'SCORE_ONLY'
  | 'FULL_SOLUTION_AND_SCORE'
  | 'ONLY_CHECK_ANSWER';

export type Theme =
  | 'DEFAULT'
  | 'FHI'
  | 'OSLOMET'
  | 'UIT'
  | 'UIB'
  | 'ISF'
  | 'USN'
  | 'NLA'
  | 'INN'
  | 'OUS'
  | 'HIMOLDE'
  | 'HIOF'
  | 'AHO'
  | 'NUBU'
  | 'NKVTS'
  | 'HLSENTERET'
  | 'RBUP'
  | 'NMBU'
  | 'NORD'
  | 'NTNU'
  | 'NORDLANDSFORSKNING'
  | 'HIVOLDA'
  | 'UNIS'
  | 'ANSGAR'
  | 'NORCE'
  | 'NOKUT'
  | 'VID'
  | 'UIA'
  | 'KHIO'
  | 'BI'
  | 'SYKEHUSET_INNLANDET'
  | 'HK'
  | 'NTNU_SAMFORSK'
  | 'VESTRE_VIKEN'
  | 'LANDBRUKSDIREKTORATET'
  | 'MF'
  | 'UIS'
  | 'EDUCSC'
  | 'PHS'
  | 'KRUS'
  | 'EDUCLOUD'
  | 'RADICH'
  | 'KIRKENSBYMISJON'
  | 'SSHF'
  | 'BARNEVERN_NOTODDEN'
  | 'LDH'
  | 'OSLONH'
  | 'NMH'
  | 'SIKT'
  | 'NORDKAPP_KOMMUNE'
  | 'HFY'
  | 'BGO'
  | 'HELSE_STAVANGER'
  | 'KULTURAKADEMIET'
  | 'BARRATTDUE'
  | 'SAMAS'
  | 'VETINST'
  | 'NIH'
  | 'BBJ'
  | 'DMMH'
  | 'NOLOGO';

export type NewDesignEnabledSetting = 'ALWAYS' | 'NEVER' | 'OPTIONAL';

type userFormWindowObject = {
  respondentGroups: RespondentGroup[];
  formTypes: FormType[];
  scoreResultDisplayTypes: ShowAnswer[];
  languageCodesAsList: FormLanguage[];
};

const _ns: nsWindowObject = window.Nettskjema;

export const Nettskjema = {
  getBaseUrl: () => _ns.BASE_URL,
  getContextPath: () => _ns.CONTEXT_PATH,
  getFormId: () => _ns.formId,
  getInvitationId: () => _ns.invitationId,
  getLanguageCode: () => _ns.languageCode,
  supportsIncludingRefererUrl: () => _ns.supportsIncludingRefererUrl,
  getCsrfPreventionToken: () => _ns.CSRF_PREVENTION_TOKEN,
  environment: () => _ns.environment,

  showTsdBanner: () => _ns.showTsdBanner,

  authenticatedInfo: () => _ns.authenticatedInfo,

  canEditForm: () => _ns.canEditForm,

  loginPath: () => _ns.loginPath,

  isLoggedIn: () => _ns.isLoggedIn,
  isEnglish: () => _ns.isEnglish,

  isSupportuser: () => _ns.isSupportuser,
  isSuperuser: () => _ns.isSuperuser,
  isLoggedInWithIdPorten: () => _ns.isLoggedInWithIdPorten,
  isAdministrativeUser: () => _ns.isAdministrativeUser,

  isPersonalDataResponsible: () => _ns.isPersonalDataResponsible,

  operationMessage: () => _ns.operationMessage,

  settingsLocked: () => _ns.settingsLocked,

  editorsThroughApiUsers: () => _ns.editorsThroughApiUsers,

  shouldShowPersonalInfoWarningPopUp: () =>
    _ns.shouldShowPersonalInfoWarningPopUp,

  breadcrumbs: () => _ns.breadcrumbs,
  currentTab: () => _ns.currentTab,

  isCodebookActivated: () => _ns.isCodebookActivated,
  tsdProject: () => _ns.tsdProject,

  errorMessage: () => _ns.errorMessage,
  formIdsForNewDelivery: () => _ns.FORM_IDS_FOR_DELIVERY_TO_NETTSKJEMA_API,
  projectsForNewDelivery: () => _ns.TSD_PROJECTS_FOR_DELIVERY_TO_NETTSKJEMA_API,
};

export const messages: { [key: string]: string } = window.messages;

export const UserForm = {
  getRespondentGroups: () => window.UserForm.respondentGroups,
  getFormTypes: () => window.UserForm.formTypes,
  getScoreResultDisplayTypes: () => window.UserForm.scoreResultDisplayTypes,
  getLanguageCodes: () => window.UserForm.languageCodesAsList,
};

const regularRespondentGroups: RespondentGroup[] = [
  'ALL',
  'FEIDE',
  'INVITED',
  'ID_PORTEN_LEVEL_3',
  'ID_PORTEN_LEVEL_4',
  'ID_PORTEN_LEVEL_3_SIGNING',
  'ID_PORTEN_LEVEL_4_SIGNING',
];

/* 15.10.2020: Lar supportuserRespondentGroups og  superuserRespondentGroups stå litt
til vi er sikre på at vi ikke trenger dem */
const supportuserRespondentGroups: RespondentGroup[] = [];

const superuserRespondentGroups: RespondentGroup[] = [];

export const respondentGroups = _ns.isSuperuser
  ? regularRespondentGroups
      .concat(supportuserRespondentGroups)
      .concat(superuserRespondentGroups)
  : _ns.isSupportuser
  ? regularRespondentGroups.concat(supportuserRespondentGroups)
  : regularRespondentGroups;

export const respondentGroupToText = (respondentGroup: string): string => {
  switch (respondentGroup) {
    case 'ALL':
      return messages.respondent_group_all;
    case 'FEIDE':
      return messages.respondent_group_uio_feide;
    case 'INVITED':
      return messages.invited;
    case 'ID_PORTEN_LEVEL_3':
      return messages.ID_porten_level_3;
    case 'ID_PORTEN_LEVEL_4':
      return messages.ID_porten_level_4;
    case 'ID_PORTEN_LEVEL_3_SIGNING':
      return messages.ID_porten_level_3_signing;
    case 'ID_PORTEN_LEVEL_4_SIGNING':
      return messages.ID_porten_level_4_signing;
    case 'FEIDE_AND_INVITED':
      return messages.allUiOusers;
    default:
      return '';
  }
};

const _person: personWindowObject = window.Person;

export const Person = {
  hasAcceptedTos: () => _person.hasAcceptedTos,
};

const _form: reactFormWindowObject = window.ReactForm;

export const Form = {
  getMetaInformation: () => _form.metaInformation,
  getFormSettings: () => _form.formSettings,
};

export default Nettskjema;
