// @flow

import { scroller } from 'react-scroll';
import type { Saga } from 'redux-saga';
import { delay } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { logError } from '../../../errorHandling';

import * as ErrorMessage from './ErrorBox';

export type RequestDeniedAction = {
  type: 'REQUEST_DENIED_DUE_TO_VALIDATION_ERROR',
};

export function* denyUserRequestDueToValidation(): Saga<void> {
  yield put({ type: 'REQUEST_DENIED_DUE_TO_VALIDATION_ERROR' });
  yield call(scrollToErrorMessageBox);
}

function* scrollToErrorMessageBox(): Saga<void> {
  yield call(delay, 0);
  yield call(delay, 0); // Effectively wraps the following in two `setTimeout`s, so the error message box has the chance to render
  yield call([scroller, scroller.scrollTo], ErrorMessage.containerId, {
    smooth: true,
  });
  const expandLink = document.querySelector(
    `#${ErrorMessage.containerId} button`,
  );
  if (expandLink == null) {
    // Calling `delay` twice above hopefully makes the following redundant. If the error hasn't been seen in the logs for a month or two, the logging can be removed.
    yield call(
      logError,
      new Error(
        'Error message toggle button not found in DOM. This probably means that scroll and focus was attempted before the error message box was displayed.',
      ),
    );
  } else {
    yield call([expandLink, expandLink.focus]);
  }
}
