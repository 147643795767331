// @flow

import type { NettskjemaGlobalObject } from './types';

const getSettings = () => {
  const valuesExposedFromFtl = (window.Nettskjema: NettskjemaGlobalObject);

  return {
    CONTEXT_PATH: valuesExposedFromFtl.CONTEXT_PATH,
    MAX_UPLOAD_FILE_SIZE_MB: valuesExposedFromFtl.MAX_UPLOAD_FILE_SIZE_MB,
    SUPPORTED_FILE_TYPES: valuesExposedFromFtl.SUPPORTED_FILE_TYPES,
    SUPPORTED_FILE_NAME_REGEXP: valuesExposedFromFtl.SUPPORTED_FILE_NAME_REGEXP,
    DATE_FORMAT: 'DD.MM.YYYY',
    TIME_FORMAT: 'hh:mm',
    formId: valuesExposedFromFtl.formId,
    invitationId: valuesExposedFromFtl.invitationId,
    formTitle: valuesExposedFromFtl.formTitle,
    formType: valuesExposedFromFtl.formType,
    formLanguage: valuesExposedFromFtl.formLanguage,
    respondentGroup: valuesExposedFromFtl.respondentGroup,
    authenticatedInfo: valuesExposedFromFtl.authenticatedInfo,
    operationMessage: valuesExposedFromFtl.operationMessage,
    formTheme: valuesExposedFromFtl.formTheme,
    baseUrl: valuesExposedFromFtl.BASE_URL,
    backendError: valuesExposedFromFtl.backendError,
    csrfPreventionToken: valuesExposedFromFtl.CSRF_PREVENTION_TOKEN,
    supportsIncludingRefererUrl:
      valuesExposedFromFtl.supportsIncludingRefererUrl,
    containsElementTypesNotSupportedByOldDesign:
      valuesExposedFromFtl.containsElementTypesNotSupportedByOldDesign,
    viewSubmissionId: valuesExposedFromFtl.viewSubmissionId,
    mode: valuesExposedFromFtl.mode,
    submitButtonText: valuesExposedFromFtl.submitButtonText,
    languageCode: valuesExposedFromFtl.languageCode,
    anonymous: valuesExposedFromFtl.anonymous,
    isEditor: valuesExposedFromFtl.isEditor,
    isTokenAuthenticated: valuesExposedFromFtl.isTokenAuthenticated,
    isOidcAuthenticated: valuesExposedFromFtl.isOidcAuthenticated,
    highSecurityServiceEnabled: valuesExposedFromFtl.highSecurityServiceEnabled,
    formIdsForNewDelivery:
      valuesExposedFromFtl.FORM_IDS_FOR_DELIVERY_TO_NETTSKJEMA_API,
    tsdProjectsForNewDelivery:
      valuesExposedFromFtl.TSD_PROJECTS_FOR_DELIVERY_TO_NETTSKJEMA_API,
  };
};

export default getSettings;
