// @flow

import { t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import * as React from 'react';
import Select, {
  components,
  DropdownIndicatorProps,
  InputProps,
} from 'react-select';
import * as colors from '../../design/colors';

type DropdownProps = {
  disabled: boolean,
  id: string,
  options: Array<{ label: string, value: number }>,
  onSelectedOption: (value: number | null) => void,
  mandatory: boolean,
  isClearable?: boolean,
  error?: boolean,
  defaultValue?: number,
};

const getDropdownStyles = (error: boolean) => {
  const focusColor = error ? colors.warning.dark : colors.focus.frameBlue;

  return {
    placeholder: provided => ({
      ...provided,
      fontSize: 18,
      color: colors.primary.dark,
    }),
    option: (provided, state) =>
      state.isDisabled && !state.isSelected
        ? { ...provided, color: '#666666' }
        : state.data.clearOption
        ? {
            ...provided,
            background: state.isFocused
              ? colors.primary.footerHover
              : colors.primary.light,
            '&:active': {
              background: colors.primary.footer,
            },
            color: state.isFocused && '#fff',
            fontStyle: 'italic',
            padding: '4px 12px',
            fontSize: 16,
          }
        : {
            ...provided,
            background: state.isSelected
              ? colors.primary.dark
              : state.isFocused && colors.primary.footerHover,
            '&:active': {
              background: colors.primary.footer,
            },
            color: (state.isFocused || state.isSelected) && '#fff',
            fontSize: 16,
          },
    valueContainer: provided => ({
      ...provided,
      padding: '1px 0 1px 8px',
      '& input:focus': {
        boxShadow: 'none !important',
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: 18,
      color: state.data.clearOption && colors.primary.dark,
    }),
    container: (provided, state) => ({
      ...provided,
      borderRadius: 5,
      boxShadow: state.isFocused && `0 0 0 2px ${focusColor}`,
      width: 322,
      maxWidth: '100%',
    }),
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused
        ? `2px solid ${focusColor}`
        : state.isDisabled
        ? '2px solid #888'
        : `2px solid ${colors.primary.dark}`,
      boxShadow: 'none',
      '&:hover': {
        borderColor: !state.isFocused && colors.primary.dark,
      },
      borderRadius: state.menuIsOpen ? 0 : 5,
      borderTopRightRadius: 5,
      borderTopLeftRadius: 5,
      zIndex: state.menuIsOpen ? 2 : 0,
      minHeight: 45,
    }),
    menu: provided => ({
      ...provided,
      marginTop: 0,
      border: `2px solid ${focusColor}`,
      boxShadow: `0 0 0 2px ${focusColor}`,
      borderTop: '0',
      borderRadius: 0,
      zIndex: 4,
      borderBottomRightRadius: 5,
      borderBottomLeftRadius: 5,
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: '375px',
      paddingTop: 0,
      paddingBottom: 0,

      '::-webkit-scrollbar': {
        width: '10px',
      },

      '::-webkit-scrollbar-track': {
        background: colors.primary.light,
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
      },

      '::-webkit-scrollbar-thumb': {
        backgroundColor: colors.primary.dark,
        borderRadius: '3px' /* roundness of the scroll thumb */,
      },
      scrollbarColor: `${colors.primary.dark} ${colors.primary.light}`,
      scrollbarWidth: 'thin',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      width: 30,
      color: colors.primary.dark,
      background:
        `${state.isDisabled ? 'transparent' : '#fff'} url(` +
        require('../../design/chevron-down.svg') +
        ') no-repeat center center',
    }),
  };
};

const CustomChevron = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}> </components.DropdownIndicator>
);

const CustomInput = (props: InputProps) => {
  const inputProps = props.selectProps.inputProps;
  return <components.Input {...props} {...inputProps} />;
};

const customComponents = {
  Input: CustomInput,
  DropdownIndicator: CustomChevron,
  IndicatorSeparator: null,
};

const Dropdown = (p: DropdownProps) => {
  const selectedOption = p.options.find(
    option => option.value === p.defaultValue,
  );

  return (
    <I18n>
      {({ i18n }) => {
        const clearOption =
          p.isClearable && p.options.length > 0
            ? [
                {
                  label: i18n._(t`Velg`) + ' …',
                  value: '',
                  clearOption: true,
                },
              ]
            : [];
        return (
          <Select
            isDisabled={p.disabled}
            placeholder={p.disabled ? '' : i18n._(t`Velg`) + ' …'}
            noOptionsMessage={() => i18n._(t`Ingen alternativer`)}
            inputId={p.id}
            onChange={option =>
              p.onSelectedOption(option.value != '' ? option.value : null)
            }
            inputProps={{
              'aria-required': p.mandatory.toString(),
            }}
            options={clearOption.concat(p.options)}
            styles={getDropdownStyles(p.error || false)}
            components={customComponents}
            value={selectedOption}
          />
        );
      }}
    </I18n>
  );
};
export default Dropdown;
