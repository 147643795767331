// @flow

import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import momentTz from 'moment-timezone';
import * as React from 'react';
// $FlowIgnore
import { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
// $FlowIgnore
import { Button, Heading, Input, Row } from 'react-usit-ui';

import * as Backend from '../backend/legacyJsonBackend';
import { displayName } from '../backend/resources';
import getSettings from '../backend/settings';
import { link, primary, warning, white } from '../design/colors/index';
import { focusFrameNoBorder } from '../design/focusFrame';
import { QuizFeedback } from '../question-elements/multiple-choice/QuizFeedback';
import { ConnectedAllPages } from './AllPages';
import errorIcon from './error.svg';
import progressBarLoading from './progressBarLoading.gif';
// $FlowIgnore
import styles from './Receipt.less';

const style = {
  '@global': {
    '.receipt-link-as-button': {
      display: 'inline-block',
      background: primary.dark,
      color: white,
      border: 'none',
      borderRadius: 3,
      borderWidth: 1,
      fontWeight: 'bold',
      padding: '5px 10px',
      margin: '10px auto',
    },
    '.receipt-link-as-button:hover': {
      background: primary.hover,
      cursor: 'pointer',
      textDcoration: 'none',
    },
    '.receipt-link-as-button:focus': {
      background: primary.hover,
      cursor: 'pointer',
      textDecoration: 'none',
    },
    sup: {
      verticalAlign: 'super',
      fontSize: 'smaller',
    },
    sub: {
      verticalAlign: 'sub',
      fontSize: 'smaller',
    },
  },
  receiptPage: {
    marginBottom: '40px',
  },

  receiptHeadlineContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    '& h1': {
      fontSize: '3.0rem',
      lineHeight: '45px',
    },
  },

  headlineIcon: {
    lineHeight: '45px',
    marginLeft: 15,
  },

  receiptFormTitle: {
    lineHeight: '27px',
    fontSize: '1.8rem',
    marginBottom: 32,
  },

  refNumberHeadline: {
    lineHeight: '34px',
    fontSize: '2.3rem',
    marginBottom: 16,
  },

  submissionId: {
    lineHeight: '24px',
    fontSize: '1.8rem',
    borderLeft: `1px solid ${link.default}`,
    marginLeft: 40,
    paddingLeft: 20,
    '& a': {
      color: link.default,
    },

    '& a:hover': {
      backgroundColor: link.hoverBG,
      color: white,
    },

    '& a:focus': {
      border: '2px solid ' + link.focusFrame,
    },
  },

  linkToSubmissionReceiptText: {
    fontSize: '1.8rem',
  },

  deliveryDateContainer: {
    lineHeight: '27px',
    fontSize: '1.8rem',
    marginTop: 8,
  },
  delivered: {
    fontSize: '1.8rem',
  },
  receiptText: {
    lineHeight: '27px',
    marginBottom: '40px',
    '& .form-question > h3': {
      marginTop: 10,
    },
    '& .user-info .score': {
      fontWeight: 'bold',
    },
    '& .form-question .mandatory': {
      color: primary.dark,
    },
    '& .submission-details': {
      '& ul': {
        display: 'inline-block',
        '& > li': {
          padding: [8, 20],
          listStylePosition: 'inside',
          listStyleType: 'square',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: '#fff',
          '&.wrong': {
            borderColor: '#ce413d',
            '& .answer-key': { color: '#ce413d' },
            '& img': {
              width: 15,
              height: 15,
              top: 2,
            },
            color: '#363534 !important',
          },
          '&.correct': {
            borderColor: '#55a12e',
            '& .answer-key': { color: '#55a12e' },
            color: '#363534 !important',
          },
          '& div, & .answer-key': {
            display: 'inline-block',
            marginLeft: 11,
          },
          '& img': {
            marginLeft: 15,
            position: 'relative',
            top: 1,
          },
          '& .answer-key': {
            textTransform: 'uppercase',
            fontWeight: 'bold',
          },
          '& .correct-answer': {
            color: '#363534 !important',
            fontWeight: 'bold',
          },
          '& .text-and-image, & .show-correct-answer-text': {
            color: '#7b7b7b',
          },
        },
      },
    },
  },

  openEmailRequestButton: {
    backgroundColor: primary.light,
    borderRadius: 8,
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    borderWidth: 0,
    padding: 13,
    marginBottom: '20px',
  },
  emailIcon: {
    marginRight: 10,
    height: 25,
  },
  emailReceiptContainer: {
    backgroundColor: primary.light,
    padding: 40,
    borderRadius: 8,
  },
  emailReceiptContainerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  receiptEmail: {
    color: '#1C5081',
  },
  inputControls: {
    marginTop: '15px',
    height: '40px',
  },
  inputEmail: {
    marginRight: ['15px', '!important'],
    display: 'inline',
    '& input': {
      borderWidth: 2,
      boxShadow: 'none',
      '&:focus': {
        ...focusFrameNoBorder,
        borderColor: 'transparent !important',
      },
    },
    '&.invalid input:invalid, &.invalid input[value=""]': {
      border: `solid 2px ${warning.dark}`,
    },
  },

  printButtonContainer: { marginTop: 50 },
  errorMessage: {
    color: '#f00',
  },
  line: {
    borderBottom: '1px dotted #d0d0d0',
    margin: [10, 0],
  },
  input: {},
  responsiveButton: { fontSize: 15 },
  surveyContent: {
    padding: '28px',
    paddingBottom: '25px',
  },
  attachmentHeadline: {
    fontSize: '30px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  attachmentInfoText: { marginBottom: '20px' },
  attachmentFailedText: {
    marginLeft: '40px',
  },
  progressBarAttachmentHeadline: { fontSize: '23px', marginBottom: '20px' },
  progressBarAttachmentContainer: { margin: '10px auto 50px auto' },
  progressBarAttachment: {
    minWidth: 280,
    backgroundColor: primary.light,
    height: '12px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '100px',
    marginRight: '300px',
  },
  attachmentDeliveryInProgress: {
    background: `url(${progressBarLoading})`,
    backgroundSize: '700px 12px',
  },
  indicatorAttachment: {
    height: '12px',
    backgroundColor: primary.dark,
    display: 'flex',
    justifyContent: 'flex-end',
    borderRadius: '100px',
  },
  attachmentFailedContainer: {
    display: 'inline-block',
    backgroundColor: warning.lighter,
    background: `url(${errorIcon}) 10px center no-repeat`,
    padding: '10px',
    marginBottom: '20px',
  },
  paperplaneIcon: {
    width: 'auto',
  },
  '@media screen and (max-width: 710px)': {
    emailReceiptContainerWrapper: {
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
    },
    paperplaneIcon: {
      paddingTop: '10px',
    },
    responsiveButton: {
      display: 'block',
      width: '100%',
      height: '70px',
    },
    input: {
      width: '100%',
      marginBottom: '20px',
    },
    inputControls: {
      width: '100%',
      height: 'auto',
    },
    emailReceiptContainer: {
      padding: 20,
    },
    openEmailRequestButton: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      minHeight: 50,
      justifyContent: 'left',
      paddingLeft: 15,
      fontSize: 18,
    },
    emailIcon: {
      marginRight: 15,
      height: 22,
    },
    surveyContent: {
      padding: '10px',
      paddingBottom: '30px',
    },
    progressBarAttachment: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
};

type Classes = { [$Keys<typeof style>]: string };
type State = {
  email: string,
  error: boolean,
};

type Props = {
  formType: Backend.FormType,
  includeSubmissionInUserReceiptEmail: boolean,
  receiptMessage: Backend.SuccessfulSubmitResponse,
  emailReceiptSent: ?boolean,
  errorMessage: ?string,
  classes: Classes,
  onClickEmailReceipt: (email: string) => void,
  submitting: boolean,
};

class ReceiptEmail extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { email: '', error: false };
  }

  handleEmailInput = event => {
    this.setState({ email: event.target.value });
  };

  validateEmailInput = () => {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    const valid = re.test(this.state.email);
    if (valid) {
      this.setState({ error: false });
    } else {
      this.setState({ error: true });
    }
    return valid;
  };

  submitEmail = () => {
    if (this.validateEmailInput()) {
      this.props.onClickEmailReceipt(this.state.email);
    }
  };

  render() {
    const frontendErrorMessage = (
      <div className={this.props.classes.errorMessage}>
        <Trans>Ugyldig e-postadresse</Trans>
      </div>
    );

    const errorMessage = (
      <div className={this.props.classes.errorMessage}>
        {!this.state.error && this.props.errorMessage}
      </div>
    );

    const receiptSent = (
      <div>
        <div>
          <Trans>
            Kvitteringen er sendt til{' '}
            <b className={this.props.classes.receiptEmail}>
              {this.state.email}
            </b>
            .
          </Trans>
        </div>
        <div>
          <Trans>
            Det kan ta noen minutter før du mottar kvitteringen, men den er på
            vei!
          </Trans>
        </div>
      </div>
    );
    const onKeyDown = event => {
      if (event.key === 'Enter') {
        this.submitEmail();
      }
    };

    const receiptText = (
      <div>
        <Trans>
          Hvis du ønsker det, kan du få en e-post med bekreftelse på at du har
          svart på skjemaet.
        </Trans>
        {this.props.includeSubmissionInUserReceiptEmail && (
          <div>
            <Trans>
              <b>E-postadressen vil bli lagret sammen med svaret du leverte.</b>
            </Trans>
          </div>
        )}
      </div>
    );

    const receiptForm = errorMessage => (
      <div>
        {receiptText}
        {errorMessage}
        {this.state.error && frontendErrorMessage}
        <div className={this.props.classes.inputControls}>
          <div
            className={classNames([
              this.props.classes.inputEmail,
              this.props.errorMessage && 'invalid',
            ])}
          >
            <Input
              type="email"
              value={this.state.email}
              onChange={this.handleEmailInput}
              onKeyDown={onKeyDown}
              className={this.props.classes.input}
            />
          </div>
          <Button
            type="primary"
            disabled={this.props.submitting}
            onClick={() => {
              this.submitEmail();
            }}
            className={this.props.classes.responsiveButton}
          >
            <Trans>Send bekreftelse</Trans>
          </Button>
        </div>
      </div>
    );

    return (
      <div
        id="email-receipt-container"
        className={this.props.classes.emailReceiptContainer}
      >
        <div className={this.props.classes.emailReceiptContainerWrapper}>
          <div>
            <Heading level={2}>
              <b>
                {!this.props.emailReceiptSent ? (
                  <Trans>Ønsker du kvittering på e-post?</Trans>
                ) : (
                  <div>
                    <Trans>Kvitteringen er sendt</Trans>
                  </div>
                )}
              </b>
            </Heading>
            {this.props.emailReceiptSent
              ? receiptSent
              : receiptForm(errorMessage)}
          </div>
          <img
            className={this.props.classes.paperplaneIcon}
            src={require('./papirfly.svg')}
            alt=""
          />
        </div>
      </div>
    );
  }
}

class ReceiptPageScript extends React.Component<{
  codebookMap: Object,
  receiptPageScript: string,
  submissionId?: number,
}> {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const hasPostMessage = window.parent && window.parent['postMessage'];
    const submissionId = this.props.submissionId;
    if (hasPostMessage) {
      setTimeout(function() {
        window.parent.postMessage('ReceiptPageScriptLoaded', '*');
      }, 15);
      if (submissionId) {
        setTimeout(function() {
          window.parent.postMessage('SubmissionId: ' + submissionId, '*');
        }, 15);
      }
    }

    const script =
      'var data = ' +
      JSON.stringify(this.props.codebookMap) +
      ';' +
      (this.props.receiptPageScript || '');
    eval(script);
  }
  render() {
    return <div id="receipt-results" />;
  }
}

const emailIcon = require('./email-icon.svg');

const OpenEmailContainerButton = (p: {
  classes: Classes,
  onClickOpenEmailReceiptContainer: () => void,
}) => (
  <div>
    <Button
      className={p.classes.openEmailRequestButton}
      onClick={() => p.onClickOpenEmailReceiptContainer()}
      type="secondary"
      onKeyDown={(e: SyntheticKeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter') {
          p.onClickOpenEmailReceiptContainer();
        }
      }}
    >
      <img alt="" className={p.classes.emailIcon} src={emailIcon} />
      <Trans> Ønsker du kvittering på e-post? </Trans>
    </Button>
  </div>
);

const ProgressBarAttachment = (p: {
  progress: number,
  attachmentDeliveryInProgress: boolean,
  classes: Classes,
}) => (
  <div className={p.classes.progressBarAttachmentContainer}>
    <div
      className={classNames([
        p.classes.progressBarAttachment,
        p.attachmentDeliveryInProgress &&
          p.classes.attachmentDeliveryInProgress,
      ])}
      role="progressbar"
      aria-valuenow={p.progress}
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div
        className={p.classes.indicatorAttachment}
        style={{ width: `${p.progress}%` }}
      ></div>
    </div>
  </div>
);

const FormTitle = () => <>{ReactHtmlParser(getSettings().formTitle)}</>;

const StyledReceiptEmail = injectSheet(style)(ReceiptEmail);

const Receipt = (p: {
  successfulSubmitResponse: Backend.SuccessfulSubmitResponse,
  emailReceiptSent: ?boolean,
  errorMessage: ?string,
  classes: Classes,
  onClickEmailReceipt: () => void,
  onClickOpenEmailReceiptContainer: () => void,
  emailReceiptBlockOpen: boolean,
  formType: Backend.FormType,
  includeSubmissionInUserReceiptEmail: boolean,
  submitting: boolean,
  form: any,
  attachmentDeliveryInProgress?: boolean,
  numberOfAttachments?: number,
  numberOfDeliveredAttachments: number,
  failedAttachments?: [],
}) => {
  useEffect(() => {
    const handleBeforeUnload = event => {
      event.preventDefault();
    };

    if (p.attachmentDeliveryInProgress) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [p.attachmentDeliveryInProgress]);

  const submissionId = p.successfulSubmitResponse.submissionId;

  const postponedSubmissionUrl =
    submissionId &&
    `${
      getSettings().CONTEXT_PATH
    }/user/submission/show.html?id=${submissionId}`;

  const anonymousForm = p.successfulSubmitResponse.anonymous;

  const deliveryDate =
    p.successfulSubmitResponse &&
    p.successfulSubmitResponse.deliveryDate &&
    anonymousForm
      ? momentTz(p.successfulSubmitResponse.deliveryDate).format('Do MMMM YYYY')
      : momentTz(p.successfulSubmitResponse.deliveryDate).format(
          'Do MMMM YYYY H:mm',
        );

  return (
    <div id="receipt" className={p.classes.receiptPage}>
      {p.successfulSubmitResponse.receiptPageScript &&
        p.successfulSubmitResponse.codebookMap && (
          <Row>
            <ReceiptPageScript
              receiptPageScript={p.successfulSubmitResponse.receiptPageScript}
              codebookMap={p.successfulSubmitResponse.codebookMap}
              submissionId={p.successfulSubmitResponse.submissionId}
            />
          </Row>
        )}
      <Row>
        {!p.attachmentDeliveryInProgress && p.successfulSubmitResponse && (
          <>
            <div className={p.classes.receiptHeadlineContainer}>
              <h1>{p.successfulSubmitResponse.receiptHeadline}</h1>
              {p.successfulSubmitResponse.receiptHeadline && (
                <div>
                  {p.successfulSubmitResponse.postponeDelivery ? (
                    <img
                      className={p.classes.headlineIcon}
                      src={require('./pauseBlueBG.svg')}
                      alt=""
                    />
                  ) : (
                    <img
                      className={p.classes.headlineIcon}
                      src={require('./checkmarkBlueBG.svg')}
                      alt=""
                    />
                  )}
                </div>
              )}
            </div>
            {p.successfulSubmitResponse.message && (
              <div>{ReactHtmlParser(p.successfulSubmitResponse.message)}</div>
            )}
            {p.successfulSubmitResponse.deliveryDate && (
              <div className={p.classes.deliveryDateContainer}>
                {p.successfulSubmitResponse.deliveryDate && (
                  <>
                    <span className={p.classes.delivered}>
                      <Trans>Levert</Trans>:
                    </span>{' '}
                    {deliveryDate}
                  </>
                )}
              </div>
            )}

            <div className={p.classes.receiptFormTitle}>
              <FormTitle />
            </div>
            {p.successfulSubmitResponse.refNumberHeadline &&
              p.successfulSubmitResponse.submissionId && (
                <>
                  <div className={p.classes.refNumberHeadline}>
                    <h2>{p.successfulSubmitResponse.refNumberHeadline}</h2>
                  </div>
                  <div className={p.classes.submissionId}>
                    <a href={postponedSubmissionUrl}>
                      {p.successfulSubmitResponse.submissionId}
                    </a>
                  </div>
                </>
              )}
            {p.successfulSubmitResponse.linkToSubmissionReceiptText && (
              <div className={p.classes.linkToSubmissionReceiptText}>
                {p.successfulSubmitResponse.linkToSubmissionReceiptText}
              </div>
            )}
            {p.successfulSubmitResponse.formReceiptText && (
              <div
                id="receiptText"
                className={classNames(
                  p.classes.receiptText,
                  styles.receiptText,
                )}
              >
                {ReactHtmlParser(p.successfulSubmitResponse.formReceiptText)}
              </div>
            )}
          </>
        )}

        {p.numberOfAttachments &&
          p.numberOfAttachments > 0 &&
          p.attachmentDeliveryInProgress && (
            <>
              <div className={p.classes.attachmentHeadline}>
                <Trans>Laster opp vedlegg</Trans>
              </div>
              <div className={p.classes.attachmentInfoText}>
                <Trans>
                  Det kan ta opptil 6 minutter å laste opp vedlegg.
                  <br />
                  Hastigheten kommer an på ditt nettverk og størrelsen på filene
                  du la ved.
                </Trans>
              </div>
              <div className={p.classes.attachmentInfoText}>
                <Trans>
                  Det er viktig at du blir på siden mens vedleggene lastes opp.
                </Trans>
              </div>
              <h2 className={p.classes.progressBarAttachmentHeadline}>
                <Trans>
                  {p.numberOfDeliveredAttachments} av {p.numberOfAttachments} er
                  lastet opp
                </Trans>
              </h2>
              <ProgressBarAttachment
                progress={
                  (p.numberOfDeliveredAttachments * 100) / p.numberOfAttachments
                }
                attachmentDeliveryInProgress={
                  p.attachmentDeliveryInProgress
                    ? p.attachmentDeliveryInProgress
                    : false
                }
                classes={p.classes}
              />
            </>
          )}
        {!p.attachmentDeliveryInProgress &&
          p.failedAttachments &&
          p.failedAttachments.length > 0 && (
            <>
              <div className={p.classes.attachmentHeadline}>
                <Trans>Noe gikk galt</Trans>
              </div>
              <div className={p.classes.attachmentInfoText}>
                <Trans>Vi klarte ikke å laste opp alle vedleggene dine.</Trans>
              </div>
              <h2 className={p.classes.progressBarAttachmentHeadline}>
                <Trans>
                  {p.numberOfDeliveredAttachments} av {p.numberOfAttachments}{' '}
                  ble lastet opp
                </Trans>
              </h2>
              {p.failedAttachments.map((failedAttachment, index) => (
                <div key={index}>
                  <div className={p.classes.attachmentFailedContainer}>
                    <span className={p.classes.attachmentFailedText}>
                      <Trans>
                        {' '}
                        Vedlegg «{failedAttachment.filename}» feilet
                      </Trans>
                    </span>
                  </div>
                </div>
              ))}
            </>
          )}

        {p.successfulSubmitResponse.requestEmail && !p.emailReceiptBlockOpen && (
          <Row>
            <OpenEmailContainerButton
              onClickOpenEmailReceiptContainer={
                p.onClickOpenEmailReceiptContainer
              }
              classes={p.classes}
            />
          </Row>
        )}
        {p.successfulSubmitResponse.requestEmail && p.emailReceiptBlockOpen && (
          <Row>
            <StyledReceiptEmail
              onClickEmailReceipt={p.onClickEmailReceipt}
              emailReceiptSent={p.emailReceiptSent}
              errorMessage={p.errorMessage}
              formType={p.formType}
              includeSubmissionInUserReceiptEmail={
                p.includeSubmissionInUserReceiptEmail
              }
              submitting={p.submitting}
            />
          </Row>
        )}

        {p.successfulSubmitResponse.quizFeedback && (
          <QuizFeedback
            quizFeedback={p.successfulSubmitResponse.quizFeedback}
          />
        )}
      </Row>

      {shouldShowPrintButton(p.form) && (
        <Row>
          <StyledPrint form={p.form} />
        </Row>
      )}
    </div>
  );
};

const shouldShowPrintButton = form => {
  const allowedUsers = [
    'espenjon-drift@uio.no',
    'eebirke-drift@uio.no',
    'iselinte-drift@uio.no',
    'dagfinb-drift@uio.no',
    'asno-drift@uio.no',
    'espenjon-drift@sysadm.uio.no',
    'eebirke-drift@sysadm.uio.no',
    'iselinte-drift@sysadm.uio.no',
    'dagfinb-drift@sysadm.uio.no',
    'asno-drift@sysadm.uio.no',
  ];
  const allowedForms = [202937, 886071, 207693];
  if (
    allowedForms.includes(form.formId) ||
    allowedUsers.includes(displayName)
  ) {
    return true;
  }

  return false;
};

// $FlowIgnore
const AllPagesWrapper = React.forwardRef((props, ref) => (
  <div className={styles.print} ref={ref}>
    <Heading level={1}>Nettskjema.no</Heading>
    <Heading level={2}>Kopi av svar til skjema {props.form.meta.title}</Heading>
    <br />
    <ConnectedAllPages />
  </div>
));

AllPagesWrapper.displayName = 'allpageswrapper';

const PrintableAllPages = p => {
  // $FlowIgnore
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className={p.classes.printButtonContainer}>
      <Button
        className={p.classes.openEmailRequestButton}
        onClick={handlePrint}
        type="secondary"
      >
        <Trans> Print ut svaret </Trans>
      </Button>
      <AllPagesWrapper form={p.form} ref={componentRef} />
    </div>
  );
};

const StyledReceipt = injectSheet(style)(Receipt);
const StyledPrint = injectSheet(style)(PrintableAllPages);

export const ReceiptContainer = connect(
  ({
    answer: {
      form,
      submissionResponse,
      emailReceiptBlockOpen,
      emailReceiptSent,
      receiptEmail,
      errorMessage,
      submitting,
      attachmentDeliveryInProgress,
      numberOfAttachments,
      numberOfDeliveredAttachments,
      failedAttachments,
    },
  }) => ({
    successfulSubmitResponse: submissionResponse,
    emailReceiptBlockOpen,
    emailReceiptSent,
    receiptEmail,
    errorMessage,
    formType: form.meta.type,
    title: form.meta.title,
    form: form,
    includeSubmissionInUserReceiptEmail:
      form.meta.includeSubmissionInUserReceiptEmail,
    submitting,
    attachmentDeliveryInProgress,
    numberOfAttachments,
    numberOfDeliveredAttachments,
    failedAttachments,
  }),
  (dispatch: any) => ({
    onClickOpenEmailReceiptContainer: () => {
      dispatch({ type: 'OPEN_EMAIL_RECEIPT_CONTAINER' });
    },
    onClickEmailReceipt: email => {
      dispatch({ type: 'SEND_EMAIL_RECEIPT_REQUESTED', email });
    },
  }),
)(StyledReceipt);

export default ReceiptContainer;
