// @flow

import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import classNames from 'classnames';
import * as React from 'react';
// $FlowIgnore
import { useState } from 'react';
import injectSheet from 'react-jss';

import {
  displayName as _displayName,
  isAuthenticated,
  logoutUrl,
} from '../backend/resources';
import getSettings from '../backend/settings';
import { ns, primary, white } from '../design/colors/index';
import adminIcon from './admin-icon.svg';
import closeIcon from './close-icon.svg';
import { shouldHideAnonymousMessage } from './InfoMessages';
import logoutIcon from './logout-icon.svg';
import nettskjemaLogoBlue from './nettskjema-logo-blue.svg';
import personIcon from './person-icon-gray.svg';
import settingsIcon from './settings-icon.svg';

const style = () => ({
  headerContainer: {
    marginBottom: 10,
    boxShadow: `0px 5px 10px ${primary.shadow}`,
    display: 'flex',
    justifyContent: 'center',
  },

  headerContent: {
    maxWidth: 1340,
    height: 96,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 25px 0 25px',
    color: primary.dark,
  },

  nettskjemaLogo: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    columnGap: 6,
    '& img': {
      width: 50,
    },

    '& span': {
      fontSize: '2.3rem',
      fontWeight: 700,
      color: primary.dark,
      letterSpacing: '0.186rem',
    },
  },

  menuContainer: {
    display: 'flex',
    columnGap: 16,
  },

  adminFormContainer: {
    marginRight: 16,

    '& a ': {
      color: primary.dark,
      fontWeight: 'bold',
      textDecoration: 'none',
    },
  },

  personContainer: {
    display: 'flex',
  },

  menuItemIcon: {
    marginRight: 5,
    width: 24,
  },

  displayName: {
    verticalAlign: 'bottom',
    color: ns.black,
  },

  headerLink: {
    textDecoration: 'underline',
    color: primary.dark,
    padding: 5,
    '&:hover': {
      backgroundColor: primary.footerHover,
      color: white,
    },

    '&:focus': {
      outline: 'none',
      boxShadow: `inset 0 0 0 2px ${primary.footerFocus}`,
    },
  },

  settingsButton: {
    display: 'none',
  },

  popoverContainer: {
    display: 'none',
  },

  popoverContent: {},

  popoverItem: {},

  closeButton: {},

  '@media all and (max-width: 840px)': {
    headerContainer: {
      width: '100%',
    },
    headerContent: {
      position: 'relative',
      padding: '0 30px 0 19px',
    },

    menuContainer: { display: 'none' },

    settingsButton: {
      display: 'block',
      background: 'none',
      border: 'none',

      '&:focus': {
        outline: 'none',
        border: '2px solid ' + primary.footerFocus,
        borderRadius: 5,
      },
    },

    popoverContainer: {
      display: 'block',
      'z-index': 10,
      position: 'absolute',
      maxWidth: 330,
      minWidth: 220,
      top: '5rem',
      right: '2rem',
      backgroundColor: white,
      borderRadius: 5,
      padding: 20,
      paddingRight: 30,
      marginLeft: 10,
      boxShadow: `5px 5px 10px ${primary.shadow}`,
    },

    closeButton: {
      display: 'flex',
      columnGap: 5,
      background: 'none',
      border: 'none',
      position: 'absolute',
      top: 16,
      right: 16,
      color: primary.dark,
      alignItems: 'center',
      fontSize: '1.6rem',
      fontWeight: 700,
      cursor: 'pointer',

      '&:hover': {
        textDecoration: 'underline',
      },
      '&:focus': {
        outline: 'none',
        boxShadow: `inset 0 0 0 2px ${primary.footerFocus}`,
        borderRadius: 5,
      },
    },

    popoverContent: {
      display: 'flex',
      flexDirection: 'column',
      'row-gap': '10px',
      marginTop: 40,
      marginBottom: 20,
    },

    popoverItem: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: 10,
      marginLeft: 40,
    },

    personContainer: {
      flexDirection: 'row',
      columnGap: 10,
    },

    adminFormContainer: {
      fontWeight: 'normal',
      marginRight: 0,
    },
  },
});

type Classes = { [$Keys<$Call<typeof style, Object>>]: string };

const HeaderContainer_ = (p: {
  infoMessageContainer: React.Node,
  classes: Classes,
}) => {
  const displayName = _displayName; // Need this for Lingui's i18n interpolation to work in tests
  const settings = getSettings();

  const [popoverOpen, setPopoverOpen] = useState(false);

  const LoggedInPerson = (p: { classes: Classes }) => (
    <div className={p.classes.personContainer}>
      <img alt={''} src={personIcon} className={p.classes.menuItemIcon} />
      <span className={p.classes.displayName}>{displayName}</span>
    </div>
  );

  const LogoutLink = (p: { includeIcon?: boolean, classes: Classes }) => (
    <>
      {p.includeIcon && (
        <img alt={''} src={logoutIcon} className={p.classes.menuItemIcon} />
      )}
      <a href={logoutUrl} className={p.classes.headerLink}>
        <Trans>Logg ut</Trans>
      </a>
    </>
  );

  const AdminFormLink = (p: { includeIcon?: boolean, classes: Classes }) => (
    <>
      {p.includeIcon && (
        <img alt={''} src={adminIcon} className={p.classes.menuItemIcon} />
      )}
      <a
        href={`${settings.CONTEXT_PATH}/user/form/${settings.formId}/settings`}
        className={p.classes.headerLink}
      >
        <Trans>Administrer dette skjemaet</Trans>
      </a>
    </>
  );

  const Popover = (p: { classes: Classes }) => {
    return (
      isAuthenticated && (
        <I18n>
          {({ i18n }) => (
            <div className={p.classes.popoverContainer}>
              <button
                className={classNames([p.classes.closeButton])}
                onClick={() => setPopoverOpen(false)}
              >
                <Trans>Lukk meny</Trans>
                <img alt={i18n._(t`Lukk`)} src={closeIcon} />
              </button>

              <div className={p.classes.popoverContent}>
                <>
                  <LoggedInPerson classes={p.classes} />

                  <div className={classNames(p.classes.popoverItem)}>
                    <LogoutLink includeIcon classes={p.classes} />
                  </div>
                </>

                {settings.isEditor && (
                  <div className={classNames(p.classes.popoverItem)}>
                    <AdminFormLink includeIcon classes={p.classes} />
                  </div>
                )}
              </div>
            </div>
          )}
        </I18n>
      )
    );
  };

  return (
    <header>
      <I18n>
        {({ i18n }) => (
          <>
            <div className={p.classes.headerContainer}>
              <div className={p.classes.headerContent}>
                <a href={`${settings.CONTEXT_PATH}/`}>
                  <div className={p.classes.nettskjemaLogo}>
                    <img alt={''} src={nettskjemaLogoBlue} />
                    <span>NETTSKJEMA</span>
                  </div>
                </a>

                {isAuthenticated && (
                  <>
                    <div className={p.classes.menuContainer}>
                      {settings.isEditor && (
                        <div className={p.classes.adminFormContainer}>
                          <AdminFormLink classes={p.classes} />
                        </div>
                      )}

                      <LoggedInPerson classes={p.classes} />
                      <div>
                        <LogoutLink classes={p.classes} />
                      </div>
                    </div>
                    <button
                      className={p.classes.settingsButton}
                      onClick={() => setPopoverOpen(!popoverOpen)}
                    >
                      <img alt={i18n._(t`Innstillinger`)} src={settingsIcon} />
                    </button>
                  </>
                )}
                {popoverOpen && <Popover classes={p.classes} />}
              </div>
            </div>
            {!shouldHideAnonymousMessage() && p.infoMessageContainer}
          </>
        )}
      </I18n>
    </header>
  );
};

const HeaderContainer = injectSheet(style)(HeaderContainer_);

export default HeaderContainer;
