import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import React from 'react';
import { Handles, Rail, Slider, Ticks } from 'react-compound-slider';
import { useSelector } from 'react-redux';

import getSettings from '../../backend/settings';
import s from './slider.less';

const Handle = ({
  max,
  min,
  handle,
  getHandleProps,
  isActive,
  setActive,
  setInactive,
  onInputValue,
  active,
  minimumValueText,
  maximumValueText,
  componentId,
  vertical,
  handleRef,
}) => {
  const { id, value, percent } = handle;

  const valueText =
    value === max
      ? maximumValueText
      : value === min
      ? minimumValueText
      : undefined;

  return (
    <div
      id={componentId}
      ref={handleRef}
      className={classnames([
        s.handle,
        vertical && s.vertical,
        { [s.active]: active },
        `${componentId}-focus`,
        !isActive && s.inactiveHandle,
      ])}
      role="slider"
      tabIndex="0"
      aria-label={componentId}
      aria-valuemax={max}
      aria-valuemin={min}
      aria-valuenow={isActive ? value : -1}
      aria-valuetext={valueText}
      onFocus={event => {
        if (!isActive) {
          setActive(true);
          onInputValue([Math.round((min + max) / 2)]);
          event.preventDefault();
        }
      }}
      onKeyPress={event => {
        if (event.key === ' ' && isActive) {
          setInactive();
          event.preventDefault();
        } else if (event.key === ' ' && !isActive) {
          setActive(true);
          onInputValue([value]);
          event.preventDefault();
        }
      }}
      style={
        isActive === true
          ? vertical
            ? {
                top: `${percent}%`,
              }
            : {
                left: `${percent}%`,
              }
          : vertical
          ? {
              top: `calc(50% - 4px)`,
            }
          : {
              left: '50%',
            }
      }
      {...getHandleProps(id)}
    ></div>
  );
};

const ValueField = ({
  value,
  onInputValue,
  min,
  max,
  setSliderActive,
  setSliderInactive,
  questionId,
  mandatory,
}) => {
  const inputFieldWidth = 60 + max.toString().length * 4;
  const [fieldValue, setFieldValue] = React.useState('');
  const [localState, setLocalState] = React.useState(false);
  const sliderValue = value != null ? value : '';

  const formLanguage = getSettings().formLanguage || getSettings().languageCode;

  return (
    <label htmlFor={questionId}>
      {formLanguage === 'en' ? <span>Value</span> : <span>Verdi</span>}
      <input
        className={s.valueField}
        style={{
          width: `${inputFieldWidth}px`,
        }}
        value={localState ? fieldValue : sliderValue}
        type="number"
        id={questionId}
        min={min}
        max={max}
        step={1}
        onBlur={() => {
          setLocalState(false);
          if (fieldValue !== value && value != null) {
            setFieldValue(value);
          }
        }}
        onFocus={() => {
          if (fieldValue !== value && value != null) {
            setFieldValue(value);
          }
          setLocalState(true);
        }}
        onChange={event => {
          const inputValue = event.target.value;
          const numberValue = Number.parseInt(inputValue);
          if (numberValue > max) {
            setFieldValue(max);
            setSliderActive();
            onInputValue([max]);
          } else if (numberValue < min) {
            setFieldValue(numberValue);
            if (!mandatory) {
              setSliderInactive();
              onInputValue([]);
            }
          } else if (inputValue === '') {
            setFieldValue('');
            setSliderInactive();
            onInputValue([]);
          } else if (!isNaN(numberValue)) {
            setFieldValue(numberValue);
            setSliderActive();
            onInputValue([numberValue]);
          }
        }}
      />
    </label>
  );
};

const Tick = ({
  tick,
  showTickValue,
  chosenValue,
  vertical,
  variedTicks,
  componentId,
}) => {
  const activeTick = chosenValue && chosenValue[0] == tick.value;
  const tickValueLength = tick.value.toString().length;
  const marginLeftHorizontalTickValue =
    showTickValue && !vertical
      ? tickValueLength == 1
        ? 10
        : 8 - tickValueLength * 2
      : 0;
  const isVariedTick = variedTicks && tick.value % 2 !== 0;

  return (
    <div
      className={classnames([
        s.tick,
        activeTick && s.activeTick,
        vertical && s.vertical,
        isVariedTick && s.variedTick,
      ])}
      style={
        vertical
          ? {
              top: `${tick.percent}%`,
            }
          : {
              left: `${tick.percent}%`,
            }
      }
    >
      {showTickValue && !isVariedTick && (
        <div
          className={classnames([
            s.tickValue,
            activeTick && s.activeTickValue,
            vertical && s.vertical,
            variedTicks && s.variedTick,
          ])}
        >
          <span
            id={componentId}
            className={vertical ? s.vertical : undefined}
            style={{
              marginLeft: `${marginLeftHorizontalTickValue}px`,
            }}
          >
            {tick.value}
          </span>
        </div>
      )}
    </div>
  );
};

const TextMarkers = ({
  vertical,
  linearScaleType,
  maximumValue,
  minimumValue,
  maximumValueText,
  minimumValueText,
  midValueText,
  showTickValues,
}) => {
  const displayMin =
    linearScaleType === 'TEXT_AND_NUM' || minimumValueText !== null
      ? minimumValueText
      : minimumValue;
  const displayMax =
    linearScaleType === 'TEXT_AND_NUM' || maximumValueText !== null
      ? maximumValueText
      : maximumValue;

  return (
    <div className={classnames([s.endMarkers, vertical && s.vertical])}>
      {!vertical && (
        <div
          className={classnames([
            s.text,
            s.endMarkerLeft,
            showTickValues && s.showTickValues,
          ])}
        >
          {displayMin}
        </div>
      )}

      {(linearScaleType === 'START_MID_END' ||
        linearScaleType === 'TEXT_AND_NUM') &&
        midValueText !== null && (
          <div
            className={classnames([
              s.text,
              s.midMarker,
              vertical && s.vertical,
              showTickValues && s.showTickValues,
            ])}
          >
            {midValueText}
          </div>
        )}
      {!vertical && (
        <div
          className={classnames([
            s.text,
            s.endMarkerRight,
            showTickValues && s.showTickValues,
          ])}
        >
          {displayMax}
        </div>
      )}
    </div>
  );
};

const RemoveValue = ({ onClick, show }) =>
  show ? (
    <button className={s.removeButton} onClick={onClick} tabIndex="0">
      <Trans>Nullstill</Trans>
    </button>
  ) : null;

const RailComponent = ({ getRailProps, vertical, handleRef }) => (
  <div
    className={classnames([s.railContainer, vertical && s.vertical])}
    {...getRailProps()}
    role="presentation"
    onClick={() => {
      if (handleRef) handleRef.current.focus();
    }}
    onKeyDown={() => {}}
  >
    <div className={classnames([s.rail, vertical && s.vertical])} />
  </div>
);

const SliderComponent = props => {
  const { linearScaleType, question, onInputValue, id } = props;

  const [isActive, setActive] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded, setLoaded]);

  const handleRef = React.useRef(null);

  const mandatory = question.mandatory;
  const min = question.minimumValue;
  const max = question.maximumValue;
  const minimumValueText = question.minimumValueText;
  const midValueText = question.midValueText;
  const maximumValueText = question.maximumValueText;
  const vertical = !question.horizontal;

  const showTickValues =
    linearScaleType === 'ALL' || linearScaleType === 'TEXT_AND_NUM';

  const showStartEndText =
    linearScaleType !== 'ALL' && linearScaleType !== 'NONE';

  const showMidText =
    (linearScaleType === 'START_MID_END' ||
      linearScaleType === 'TEXT_AND_NUM') &&
    midValueText !== null;

  const chosenValue = useSelector(
    state =>
      state.answer.inputs.find(
        input => input.questionId === question.questionId,
      ).value.given,
  );

  /* Beregninger av margin-left på vertical slider, topp- og bunntekst. 
     Iom. at vi har absoluttverdier på brukerinput (tickvalues), så må dette gjøres. 
  */

  const maxElement = document.getElementById(`${id}-tick-0`);
  const clientWidthForMaxValue =
    maxElement !== null ? maxElement.clientWidth : 0;

  const marginLeftOnVerticalScaleContainer =
    vertical && showTickValues && showMidText
      ? 60 + clientWidthForMaxValue
      : vertical && !showTickValues && showMidText
      ? 40
      : vertical && showTickValues && !showMidText
      ? 100
      : vertical && 100;

  const marginLeftOnverticalEdgeText =
    vertical && showTickValues && showMidText
      ? 117 + clientWidthForMaxValue
      : vertical && !showTickValues && showMidText
      ? 105
      : vertical && showTickValues && !showMidText
      ? 15
      : vertical && 25;

  const marginLeftOnverticalBottomControls =
    vertical && showTickValues && showMidText
      ? 150 + clientWidthForMaxValue
      : vertical && !showTickValues && showMidText
      ? 135
      : vertical && showTickValues && !showMidText
      ? 50
      : vertical && 48;

  const getTickValues = () => {
    if (!question.rangeMarksShown) {
      return [min, max];
    } else {
      return null;
    }
  };

  const getNumberOfTicks = () => {
    if (min === 0 && max === 100) {
      return 20;
    }
    return max - min < 11 ? max - min : 11;
  };

  const setSliderActive = () => {
    setActive(true);
  };

  const setSliderInactive = () => {
    if (!mandatory) {
      onInputValue([]);
      setActive(false);
      setLoaded(false);
    }
  };
  return (
    <div className={classnames([s.slid, vertical && s.vertical])}>
      {vertical && (
        <div
          className={classnames([
            s.verticalEdgeText,
            s.top,
            vertical && showMidText && s.leftContainerShown,
          ])}
          style={{ marginLeft: `${marginLeftOnverticalEdgeText}px` }}
        >
          {maximumValueText}
        </div>
      )}
      <div className={classnames([s.sliderBox, vertical && s.vertical])}>
        {linearScaleType !== 'ALL' &&
          linearScaleType !== 'NONE' &&
          ((!vertical && showStartEndText) || (vertical && showMidText)) && (
            <TextMarkers
              questionId={id}
              vertical={vertical}
              linearScaleType={linearScaleType}
              minimumValue={min}
              maximumValue={max}
              minimumValueText={minimumValueText}
              midValueText={midValueText}
              maximumValueText={maximumValueText}
              showTickValues={showTickValues}
            />
          )}

        <div style={{ marginLeft: `${marginLeftOnVerticalScaleContainer}px` }}>
          <Slider
            vertical={vertical}
            reversed={vertical}
            className={classnames([s.sliderContainer, vertical && s.vertical])}
            domain={[min, max]}
            step={1}
            mode={2}
            values={[
              chosenValue != null && chosenValue.length != 0
                ? chosenValue[0]
                : -1,
            ]}
            onChange={value => {
              if (value >= min && loaded) {
                setActive(true);
                onInputValue(value);
              }
            }}
          >
            <Ticks count={getNumberOfTicks()} values={getTickValues()}>
              {({ ticks }) => (
                <div className="slider-ticks">
                  {ticks.map((tick, idx) => (
                    <Tick
                      variedTicks={getNumberOfTicks() === 20}
                      key={tick.id}
                      tick={tick}
                      count={ticks.length}
                      showTickValue={showTickValues}
                      chosenValue={chosenValue}
                      vertical={vertical}
                      componentId={`${id}-tick-${idx}`}
                    />
                  ))}
                </div>
              )}
            </Ticks>
            <Rail>
              {({ getRailProps }) => (
                <RailComponent
                  getRailProps={getRailProps}
                  vertical={vertical}
                  handleRef={handleRef}
                />
              )}
            </Rail>
            <Handles>
              {props => {
                const { handles, getHandleProps, activeHandleID } = props;

                return (
                  <div className="slider-handles">
                    {handles.map(handle => (
                      <Handle
                        componentId={`${id}-handle-${handle.id}`}
                        active={activeHandleID === handle.id}
                        isActive={isActive}
                        setActive={setSliderActive}
                        setInactive={setSliderInactive}
                        onInputValue={onInputValue}
                        linearScaleType={linearScaleType}
                        min={min}
                        max={max}
                        minimumValueText={minimumValueText}
                        midValueText={midValueText}
                        maximumValueText={maximumValueText}
                        key={handle.id}
                        handle={handle}
                        getHandleProps={getHandleProps}
                        vertical={vertical}
                        handleRef={handleRef}
                      />
                    ))}
                  </div>
                );
              }}
            </Handles>
          </Slider>
        </div>
      </div>
      {vertical && (
        <div
          className={classnames([
            s.verticalEdgeText,
            s.bottom,
            vertical && showMidText && s.leftContainerShown,
          ])}
          style={{ marginLeft: `${marginLeftOnverticalEdgeText}px` }}
        >
          {minimumValueText}
        </div>
      )}
      <div
        className={classnames([
          s.bottomControls,
          vertical && s.vertical,
          vertical && showMidText && s.leftContainerShown,
        ])}
        style={{ marginLeft: `${marginLeftOnverticalBottomControls}px` }}
      >
        <ValueField
          min={min}
          max={max}
          onInputValue={onInputValue}
          setSliderActive={setSliderActive}
          setSliderInactive={setSliderInactive}
          value={chosenValue}
          questionId={question.questionId}
          mandatory={mandatory}
        />
        {!mandatory && (
          <RemoveValue onClick={setSliderInactive} show={isActive} />
        )}
      </div>
    </div>
  );
};

export default SliderComponent;
