// @flow

import { Trans } from '@lingui/macro';
import React from 'react';
import { connect } from 'react-redux';
import { AppContainer, GridContainer } from 'react-usit-ui';
import type { Dispatch } from 'redux';

import type { AnswerAction as Action } from '../actions';
import ErrorContainer from '../backend/ErrorContainer.js';
import getSettings from '../backend/settings';
import ConnectedFooter from '../components/Footer.js';
import Header from '../components/Header.js';
import { ConnectedInfoMessageContainer } from '../components/InfoMessages';
import ErrorBoundary from '../ErrorBoundary';
import { ModalRoot } from '../modals';
import { FormDisplay } from './FormDisplay';
import * as ModeTypes from './types';

const settings = getSettings();
const backendError = settings.backendError;

const AppContainerId = 'answer-app-container';

const fallbackMessage = (
  <p>
    <Trans>Feil under visning av siden. Våre utviklere er på saken.</Trans>
  </p>
);

const StandAloneApp = (p: {
  mode: ModeTypes.StandaloneMode,
  displayOldDesignSwitch: boolean,
}) => {
  const footer = (
    <ErrorBoundary name="Footer">
      <ConnectedFooter
        mode={p.mode}
        displayOldDesignSwitch={p.displayOldDesignSwitch}
      />
    </ErrorBoundary>
  );

  return (
    <AppContainer id={AppContainerId} footer={footer}>
      <ErrorBoundary name="Modal">
        <ModalRoot />
      </ErrorBoundary>
      <ErrorBoundary name="Header">
        <Header
          infoMessageContainer={
            p.mode === 'regular' && <ConnectedInfoMessageContainer />
          }
        />
      </ErrorBoundary>
      <GridContainer role="main">
        {backendError == null ? (
          <ErrorBoundary name="Form" fallback={fallbackMessage}>
            <FormDisplay mode={p.mode} onAbort={undefined} />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary name="BackendError" fallback={fallbackMessage}>
            <ErrorContainer error={backendError} />
          </ErrorBoundary>
        )}
      </GridContainer>
    </AppContainer>
  );
};

const EmbeddedApp = (p: { mode: ModeTypes.IframeMode }) => (
  <AppContainer id={AppContainerId}>
    <GridContainer>
      {backendError == null ? (
        <FormDisplay mode={p.mode} onAbort={undefined} />
      ) : (
        <ErrorContainer error={backendError} />
      )}
    </GridContainer>
  </AppContainer>
);

const AdminApp = (p: { mode: ModeTypes.AdminMode }) => (
  <AppContainer id={AppContainerId}>
    {backendError == null ? (
      <FormDisplay mode={p.mode} onAbort={undefined} />
    ) : (
      <ErrorContainer error={backendError} />
    )}
  </AppContainer>
);

const getScreenWidth = () => {
  const width = window.innerWidth;
  let size;
  if (width <= 320) {
    size = 'max320';
  } else if (width <= 360) {
    size = 'max360';
  } else if (width <= 375) {
    size = 'max375';
  } else if (width <= 400) {
    size = 'max400';
  } else if (width <= 500) {
    size = 'max500';
  } else if (width <= 640) {
    size = 'max640';
  } else if (width <= 980) {
    size = 'max980';
  } else {
    size = 'min980';
  }
  return {
    [`answer.width.${size}`]: 1,
  };
};

// $FlowIgnore
const useMountEffect = fun => React.useEffect(fun, []);

const App = (p: {
  mode: ModeTypes.ModeWithEndpoint,
  displayOldDesignSwitch: boolean,
  receiptPage: boolean,
  count: any => {},
}) => {
  useMountEffect(() => {
    p.mode !== 'preview' &&
      p.mode !== 'iframeEmbedded' &&
      p.count(getScreenWidth());
  });

  return p.mode === 'iframeEmbedded' ? (
    <EmbeddedApp mode={p.mode} />
  ) : p.mode === 'preview' ? (
    <AdminApp mode={p.mode} onAboirt={() => {}} />
  ) : (
    <StandAloneApp
      mode={p.mode}
      displayOldDesignSwitch={p.displayOldDesignSwitch}
    />
  );
};

export const AppForMode = connect(
  (state, ownProps: { mode: ModeTypes.ModeWithEndpoint }) => ({
    mode: ownProps.mode,
    receiptPage: state.answer.submissionResponse !== null,
    displayOldDesignSwitch:
      state.answer.currentPageIndex === 0 &&
      state.answer.submissionResponse == null &&
      !state.answer.formLoadingError &&
      !state.answer.submissionDeleted &&
      !settings.backendError &&
      !settings.containsElementTypesNotSupportedByOldDesign,
  }),
  (dispatch: Dispatch<Action>) => ({
    count: data => {
      dispatch({
        type: 'COUNT',
        data,
      });
    },
  }),
)(App);
