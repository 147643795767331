// @flow

import { Trans, t } from '@lingui/macro';
import classNames from 'classnames';
import * as React from 'react';
// $FlowIgnore
import { useState } from 'react';
import injectSheet from 'react-jss';
// $FlowIgnore
import { useSelector } from 'react-redux';
import { responsive } from 'react-usit-ui';

import { ns, warningMessage } from '../design/colors/index';
import { underlineButtonLink } from '../design/focusFrame';
import i18n from '../i18n';
import alert from './alert-red.svg';
import collapse from './collapse-red.svg';
import expand from './expand-red.svg';

const style = {
  warningMessageWrapper: {
    backgroundColor: warningMessage.background,
    color: ns.black,
    border: '2px solid',
    borderColor: warningMessage.border,
    borderRadius: 4,
    padding: [20, 25],
    marginBottom: 40,
    lineHeight: '28px',

    '&.showAtBottom': {
      marginBottom: 0,
    },
  },
  warningMessageContainer: {
    background: `url(${alert}) 0 0 no-repeat`,
    backgroundSize: 'initial',
    margin: 'auto',
    maxWidth: 970,
    paddingLeft: 50,
  },
  warningMessageShort: {
    paddingTop: 5,
  },
  warningMessageShortText: {
    color: ns.black,
    fontSize: 18,
    marginRight: 5,
  },
  expandCollapse: {
    background: `url(${expand}) no-repeat`,
    backgroundPosition: '100% 50%',
    paddingRight: 15,
    cursor: 'pointer',
    color: warningMessage.link,
    border: 'none',
    fontSize: 18,
    fontWeight: 'bold',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    lineHeight: '28px',
    ...underlineButtonLink,
  },
  collapse: {
    background: `url(${collapse}) no-repeat`,
    backgroundPosition: '100% 50%',
  },
  warningMessageExpanded: {
    fontSize: 16,
    color: ns.black,
    marginTop: 10,
  },
  warningLink: {
    color: warningMessage.link,
    fontWeight: 'bold',
    textDecoration: 'underline',
    '& a': {
      color: warningMessage.link,
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
  },
  warningMessageList: {
    listStyleType: 'square',
    marginLeft: 18,
  },
  warningMessageListElement: {
    marginBottom: 10,
  },
  [responsive.media.max640]: {
    warningMessageWrapper: {
      maxWidth: '100%',
      padding: [10, 10],
    },
    warningMessageContainer: {
      background: `url(${alert}) 0 6px no-repeat`,
      backgroundSize: 25,
      paddingLeft: 40,
    },
    warningMessageShortText: {
      fontSize: 16,
      hyphens: 'auto',
      '-webkit-hyphens': 'auto',
      '-ms-hyphens': 'auto',
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
    },
    expandCollapse: {
      fontSize: 16,
    },
    warningMessageExpanded: {
      fontSize: 15,
    },
  },
};

type Classes = { [$Keys<typeof style>]: string };

const _HighSecurityServiceWarningContainer = (p: {
  classes: Classes,
  showAtBottom?: boolean,
}) => {
  const [warningExpanded, setWarningExpanded] = useState(false);
  const form = useSelector(state => state.answer.form);
  const highSecurityEditorContactMessageAsLink = form
    ? form.meta.highSecurityEditorContactMessageAsLink
    : '';

  const highSecurityServiceInfoUrl = i18n._(
    t`https://www.uio.no/tjenester/it/adm-app/nettskjema/hjelp/opprette/nettskjema-og-sensitive-data.html`,
  );
  return (
    <div
      className={classNames([
        p.classes.warningMessageWrapper,
        p.showAtBottom && 'showAtBottom',
      ])}
    >
      <div className={p.classes.warningMessageContainer}>
        <div className={p.classes.warningMessageShort}>
          <span className={p.classes.warningMessageShortText}>
            <Trans>
              Skjemaet er ikke klargjort for å samle inn svar. Ikke fyll inn
              reelle opplysninger i dette skjemaet.
            </Trans>
          </span>

          <button
            className={classNames([
              p.classes.expandCollapse,
              warningExpanded && p.classes.collapse,
            ])}
            onClick={() => setWarningExpanded(!warningExpanded)}
          >
            {warningExpanded ? <Trans> Lukk</Trans> : <Trans> Vis mer</Trans>}
          </button>
        </div>
        {warningExpanded && (
          <div className={p.classes.warningMessageExpanded}>
            <ul className={p.classes.warningMessageList}>
              <li className={p.classes.warningMessageListElement}>
                <Trans>
                  Skjemaet må knyttes opp mot Tjenester for sensitive data (TSD)
                  før innsamlingen av reelle data kan starte
                </Trans>
              </li>
              <li className={p.classes.warningMessageListElement}>
                <Trans>
                  Skjemaet kan for øyeblikket kun brukes til testing
                </Trans>
              </li>
              <li className={p.classes.warningMessageListElement}>
                <Trans>
                  Hvis du er blitt invitert til å delta i den faktiske
                  undersøkelsen,&nbsp;
                  <span
                    className={p.classes.warningLink}
                    dangerouslySetInnerHTML={{
                      __html: highSecurityEditorContactMessageAsLink,
                    }}
                  ></span>
                </Trans>
              </li>
            </ul>

            <div>
              <Trans>
                <a
                  href={highSecurityServiceInfoUrl}
                  className={p.classes.warningLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mer om spørreskjema for sensitive data
                </a>
              </Trans>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const HighSecurityServiceWarningContainer = injectSheet(style)(
  _HighSecurityServiceWarningContainer,
);
