export const primary = {
  footer: '#002a53',
  footerHover: '#4977af',
  footerFocus: '#0398e2',
  dark: '#1c5081',
  hover: '#94c5ee',
  lighter: '#94c5ee',
  light: '#e0effd',
  border: '#aed3f3',
  shadow: '#4977af4c',
};

export const warning = {
  dark: '#c50000',
  lighter: '#FFd3d3',
  light: '#fff2f2',
};

export const ns = {
  grey: '#4d4d4d',
  greyishBrown: '#555555',
  greyLight: '#e2e2e2',
  black: '#222222',
  white: '#fff',
};

export const disabledBackground = '#edebeb';

export const white = '#ffffff';

export const link = {
  default: '#1963d3',
  hoverBG: '#4977af',
  focusFrame: '#0398e2',
};

export const infoMessage = {
  background: '#f1f9ff',
  fontColor: ns.black,
};

export const warningMessage = {
  background: warning.light,
  border: warning.lighter,
  link: warning.dark,
};

export const operationMessage = {
  background: '#0278ad',
};

export const focus = {
  frameBlue: '#00a7fd',
};
